<template>
  <div class="home">
    <div class="navigation">
      <span @click="gorecommend">图书推荐</span>
      <i class="el-icon-arrow-right"></i>
      <span @click="gorecommend(dataForm.publishRange)">{{
        dataForm.publishRange == "0" ? "长销图书" : "新书出版"
      }}</span>
      <i class="el-icon-arrow-right"></i>
      <span class="nav_info">图书详情</span>
    </div>
    <figure   class="pictop" @click="buy(dataForm.purchaseLink)">
      <img :src="dataForm.coverUrl" alt="" />
    </figure>
    <div class="phonebookdetail">
      <div class="bookName">{{ dataForm.bookName }}</div>
      <div class="author">作者:{{ dataForm.author }}</div>

      <div class="buybutton" v-if="dataForm.purchaseLink" @click="buy(dataForm.purchaseLink)" >
        <svg
          t="1685456730217"
          viewBox="0 0 1028 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="8974"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="200.78125"
          height="200"
        >
          <path
            d="M332.8 790.528q19.456 0 36.864 7.168t30.208 19.968 20.48 30.208 7.68 36.864-7.68 36.864-20.48 30.208-30.208 20.48-36.864 7.68q-20.48 0-37.888-7.68t-30.208-20.48-20.48-30.208-7.68-36.864 7.68-36.864 20.48-30.208 30.208-19.968 37.888-7.168zM758.784 792.576q19.456 0 37.376 7.168t30.72 19.968 20.48 30.208 7.68 36.864-7.68 36.864-20.48 30.208-30.72 20.48-37.376 7.68-36.864-7.68-30.208-20.48-20.48-30.208-7.68-36.864 7.68-36.864 20.48-30.208 30.208-19.968 36.864-7.168zM930.816 210.944q28.672 0 44.544 7.68t22.528 18.944 6.144 24.064-3.584 22.016-13.312 37.888-22.016 62.976-23.552 68.096-18.944 53.248q-13.312 40.96-33.28 56.832t-49.664 15.872l-35.84 0-65.536 0-86.016 0-96.256 0-253.952 0 14.336 92.16 517.12 0q49.152 0 49.152 41.984 0 20.48-9.728 35.84t-38.4 14.336l-49.152 0-94.208 0-118.784 0-119.808 0-99.328 0-55.296 0q-20.48 0-34.304-9.216t-23.04-24.064-14.848-32.256-8.704-32.768q-1.024-6.144-5.632-29.696t-11.264-58.88-14.848-78.848-16.384-87.552q-19.456-103.424-44.032-230.4l-76.8 0q-15.36 0-25.6-7.68t-16.896-18.432-9.216-23.04-2.56-22.528q0-20.48 13.824-33.792t37.376-12.288l103.424 0q20.48 0 32.768 6.144t19.456 15.36 10.24 18.944 5.12 16.896q2.048 8.192 4.096 23.04t4.096 30.208q3.072 18.432 6.144 38.912l700.416 0zM892.928 302.08l-641.024-2.048 35.84 185.344 535.552 1.024z"
            p-id="8975"
          ></path>
        </svg>
        <span >
            点击购买
        </span>
      
      </div>
    </div>
    <div class="content">
      <section class="ibsn">
        ISBN：{{ dataForm.isbn }} / 出版日期：{{
          dataForm.publicationTime && dataForm.publicationTime.substring(0,7)
        }}
      </section>
      <section class="title">{{ dataForm.bookName }}</section>
      <ul class="source">
        <li>作者：{{ dataForm.author }}</li>
        <li>ISBN： {{ dataForm.isbn }}</li>
        <li @click="goall(dataForm)">类别：{{ getType(dataForm.category)}}</li>
        <li>
          出版日期：{{
            dataForm.publicationTime && dataForm.publicationTime.substring(0, 7)
          }}
        </li>
      </ul>
      <section class="price" >
        <div v-if="dataForm.purchaseLink" class="buy-button" @click="buy(dataForm.purchaseLink)">
          <span  >
                 点击购买
          </span>
     
        </div>
        <div><span>定价：</span>￥{{changePrice(dataForm.price)  }}</div>
      </section>
      <ul class="two">
        <li>定价: ￥{{ dataForm.price }}</li>
        <li>
          出版日期：
          {{ dataForm.publicationTime && dataForm.publicationTime.substring(0, 10) }}
        </li>
      </ul>
      <ul class="one">
        <li @click="goall(dataForm)">
          类别：  <span> {{ getType(dataForm.category)}}</span>
        </li>
        <li>ISBN： {{ dataForm.isbn }}</li>
      </ul>

      <section class="synopsis">
        <el-tabs v-model="activeName">
          <el-tab-pane  class="contenthtml" label="内容简介" name="first">
              <div class="ql-container ql-snow">
                  <div class="ql-editor">
                    <div v-html="dataForm.contentIntro"></div>
                  </div>
                </div>
           
          </el-tab-pane>
          <el-tab-pane label="目录" name="second" v-if="dataForm.catalogue">
              <div class="ql-container ql-snow">
                  <div class="ql-editor">
                     <div class="contenthtml" v-html="dataForm.catalogue"></div>
                  </div>
                </div>
           
          </el-tab-pane>
        </el-tabs>
      </section>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "book",
  data() {
    return {
      dataForm: {},
      activeName: "first",
      Typeoptions: [],
    };
  },
  created() {
    this.getInfo();
    this.getInfoAll()
  },
  methods: {
    decode(base64) {
      // 对base64转编码
      // var decode = atob(base64);
      // // 编码转字符串
      // var str = decodeURI(decode);
      return base64;
    },
      changePrice(price){
         let  num = price + ''
      if (!/\./.test(num)) {
          num += '.00'
      }
      var arrayNum = num.split('.')
        if (arrayNum[1].length < 2) {
          num += '0'
        }
      return num 
    },
    buy(url) {
      // window.location.href = url;
      if(!url){
        return
      }
      window.open(url)
    },
    gorecommend(msg) {
      this.$router.push({ path: "/recommend", query: { publishRange: msg } });
    },
    goall(msg) {
      console.log('ggggooooo');
      this.$router.push({ path: "/recommend", query: { category: msg.category,publishRange:'0' } });
    },
    getType(id) {
      let name = "";
      this.Typeoptions.forEach((type) => {
        if (type.id == id) {
          name = type.categoryName;
          console.log(name,'namenamename');

        }
      });
      return name;
    },
    getInfoAll() {
      this.$http
        .get(`/h5sysmenu/bookpage/`, { params: {} })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          console.log(res.data, "getInfo");
          this.Typeoptions = res.data.list;
        })
        .catch(() => {});
    },

    getInfo() {
      this.$http
        .get(`/books/getBookInfoById`, { params: { id: this.$route.query.id } })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.dataForm = {
            ...res.data,
            contentIntro: this.decode(res.data.contentIntro),
            catalogue: this.decode(res.data.catalogue),
          };
          console.log(this.dataForm, "this.dataForm");
        })
        .catch(() => {});
    },
  },
  components: {},
};
</script>
<style lang="scss" scoped>

.home {
  padding: 0.6rem 0.4rem;
  padding-top: 1.16rem;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  position: relative;
  .contenthtml{
    color: #000;
  }
  .pictop{
    cursor: pointer;
  }
  .navigation {
    position: absolute;
    // left: 0.64rem;
    top: 0.7rem;
    span {
      font-size: 0.14rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 0.2rem;
      cursor: pointer;
    }
    .nav_info {
      font-size: 0.14rem;

      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 0.2rem;
    }
    i {
      color: rgba(178, 1, 3, 1);
      font-size: 0.16rem;
      margin: 0.05rem;
      font-weight: 600;
    }
  }

  .phonebookdetail {
    display: none;
  }
  figure {
    width: 4.5rem;
    height: 4.5rem;
    background: #ffffff;
    border-radius: 0.02rem;
    border: 0.01rem solid #eeeeee;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .content {
    width: 8.68rem;
    // ul{
    //   display: none;
    // }
    .one,
    .two {
      display: none;
    }
  }
  .title {
    font-size: 0.24rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    line-height: 0.32rem;
    margin-bottom: 0.24rem;
  }
  .source {
    box-sizing: border-box;
    width: 8.68rem;
    height: 1.08rem;
    background: #f8f8f8;
    border-radius: 0.02rem;
    padding: 0.24rem 0.4rem;
    display: flex;
    flex-wrap: wrap;
    li {
      width: 4.34rem;
      font-size: 0.14rem;
      margin-bottom: 0.16rem;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 0.22rem;
    }
    li:nth-child(2n) {
      width: 3.54rem;
    }
  }
  .price {
    margin-top: 0.25rem;
    margin-bottom: 0.4rem;
    font-size: 0.16rem;
    display: flex;
    line-height: 0.4rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.65);
    .buy-button {
      margin-right: 0.23rem;
      width: 1.48rem;
      height: 0.4rem;
      background: var(--backgroundColor);
      border-radius: 0.02rem;
      font-weight: 500;
      color: #ffffff;
      line-height: 0.4rem;

      font-size: 0.16rem;
      text-align: center;
      cursor: pointer;
    }
  }

  /deep/ .el-tabs__item {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    font-size: 0.16rem;
  }
  /deep/ .el-tabs__nav-wrap::after {
    height: 0.01rem;
    background: #e4ccad;
  }
  /deep/ .el-tabs__active-bar {
    background: var(--backgroundColor);
  }
  /deep/ .el-tabs__item.is-active {
    color: var(--backgroundColor);
  }
  /deep/ .el-tabs__content {
    font-size: 0.18rem;

    font-weight: 400;
    // color: rgba(0, 0, 0, 0.45);
    line-height: 0.38rem;
  }
  .ibsn {
    display: none;
  }
  .buybutton {
    display: none;
  }
}

@media (max-width: 768px) {
  .home {
    margin: 0;
    flex-wrap: wrap;
    padding: 0;
    justify-content: left;
    .navigation {
      display: none;
    }

    figure {
      height: 1.4rem;
      width: 1.4rem;
      margin: 0.16rem;
    }
    .phonebookdetail {
      display: block;
      margin-top: 0.16rem;
      .bookName {
        font-size: 0.16rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 0.24rem;
        margin-bottom: 0.2rem;
        width: 1.89rem;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden; //溢出内容隐藏
        text-overflow: ellipsis; //文本溢出部分用省略号表示
        display: -webkit-box; //特别显示模式
        -webkit-line-clamp: 1; //行数
        line-clamp: 1;
        -webkit-box-orient: vertical; //盒子中内容竖直排列
      }
      .author {
        // width: 0.88rem;
        max-width: 1.5rem;
        display: inline-block;
        text-align: left;
        padding: 0  .1rem;
        background: #f3e3e3;
        border-radius: 0.02rem;
        font-size: 0.12rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #b20103;
        line-height: 0.24rem;
        // text-align: center;
      }

      .buybutton {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.2rem;
        height: 0.23rem;
        
        background: #b20103;
        border-radius: 0.04rem;
        line-height: 0.25rem;
        text-align: center;
        margin-top: 0.14rem;
        font-size: .13rem;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      flex-direction: column-reverse;
      padding: 0.12rem 0.16rem;
      border-top: 0.1rem solid rgba(246, 246, 246, 1);
      section {
        display: none;
      }
      .one {
        // margin-top: .1rem;
        border-bottom: 1px solid #eeeeee;
        li{
          display: flex;
          align-items: center;
        }

        span {
          display: inline-block;
          color: #b20103;
          // border-bottom: 1px solid #b20103;
          line-height: .15rem;
          width: 78%;
          text-decoration:underline;
        }
      }
      .one,
      .two {
        display: flex;
      }
      ul {
        // display: block;
        height: 0.52rem;
        display: flex;
        line-height: 0.52rem;
        li {
          display: flex;
          align-items: center;
          width: 50%;
          line-height: 0.22rem;
          height: 0.52rem;
          overflow: hidden;
          font-size: 0.12rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
        }
      }
      .source {
        display: none;
      }
      .synopsis {
        display: block;
        order: -1;
        position: relative;
        padding-top: 0.34rem;
        margin-bottom: 0.24rem;
      }
      .synopsis::before {
        content: "";
        display: block;
        height: 0.1rem;
        width: 100vw;
        position: absolute;
        top: 0;
        left: -0.16rem;
        background: #f6f6f6;
      }
      /deep/ .el-tabs__nav-wrap::after {
        background: #eeeeee;
      }
    }
    // .price {
    //   // order: -1;
    //   margin: 0;
    //   width: 0.88rem;
    //   height: 0.36rem;
    //   font-size: 0.24rem;

    //   font-weight: 500;
    //   color: #c3282b;
    //   line-height: 0.36rem;
    //   span {
    //     content: "";
    //     display: none;
    //   }
    //   .buy-button {
    //     display: none;
    //   }
    // }
    // .price::before {
    //   content: "￥";
    //   font-size: 0.12rem;

    //   line-height: 0.44rem;
    // }
    // .source {
    //   margin: 0.12rem 0;
    //   padding: 0;
    //   display: flex;
    //   width: auto;
    //   background: #ffffff;
    //   height: auto;

    //   li {
    //     width: auto;
    //     padding: 0 0.08rem;
    //     height: 0.24rem;
    //     background: #f3e3e3;
    //     border-radius: 0.02rem;
    //     text-align: center;
    //     line-height: 0.24rem;
    //     margin: 0;
    //     margin-right: 0.08rem;
    //   }
    //   li:nth-child(2n) {
    //     display: none;
    //   }
    // }
    // .title {
    //   width: 3.43rem;

    //   font-size: 0.16rem;

    //   font-weight: 500;
    //   color: rgba(0, 0, 0, 0.85);
    //   line-height: 0.24rem;
    //   margin-bottom: 0.12rem;
    // }
    // .ibsn {
    //   display: inline;
    //   padding: 0 0.08rem;

    //   line-height: 0.24rem;
    //   font-size: 0.12rem;

    //   font-weight: 400;
    //   color: rgba(0, 0, 0, 0.65);

    //   background: #eeeeee;
    //   border-radius: 0.02rem;
    //   margin-bottom: 0.26rem;
    // }
  }
  .buybutton {
    height: 0.56rem;
    width: 100vw;

    background: var(--backgroundColor);
    font-size: 0.16rem;

    font-weight: 400;
    color: #ffffff;
    line-height: 0.24rem;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 0.16rem;
      height: 0.16rem;
      fill: #ffffff;
      margin-right: 0.08rem;
    }
  }
}
</style>
